import React, { useState } from 'react'
import { useParams } from "react-router-dom"
import axios from 'axios';
import { useEffect } from 'react';
import './ProductCategory.css'
import Product from './Product';
import NavMenu from './NavMenu'
import configData from './Config.json';

function ProductCategory() {
    const { id } = useParams();
    const [category, setCategory] = useState('');

    useEffect(() => {
        console.log(id)
        const url = configData.CATEGORY_URL
        const data = {
            categoryid: id
        };
        axios
            .post(url, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
            })
            .then(res => {
                console.log(res.data)
                const category = res.data;
                setCategory(category);
                console.log('Category received');
            })
    }, []);

    return (
        <div className='productcategory'>
            <NavMenu />
            <div className='productcategory__title'>Product Category</div>
            <div className='productcategory__row'>
                {
                    category.length != 0 ? (category
                        .map(product =>
                            <Product
                                key={product.id}
                                id={product.id}
                                name={product.name}
                                image={product.images}
                                price={product.price}
                                currency={product.currencyprice}
                            />
                        )) : null
                }
            </div>
        </div>
    )
}

export default ProductCategory