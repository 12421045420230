import React, { useState, useEffect } from 'react'
import './Home.css'
import Slideshow from './Slideshow'
import NavMenu from './NavMenu'
import Product from './Product'
import { Helmet } from 'react-helmet';
import axios from 'axios';
import configData from './Config.json';
import { Link } from 'react-router-dom';

function Home() {
    const [rowdata, setRowData] = useState([]);

    useEffect(() => {
        const url = configData.LANDINGPAGE_URL
        axios
            .post(url, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
            })
            .then(res => {
                if (res.data instanceof Array) setRowData(res.data);
            })
    }, []);

    return (
        <div className="home">
            <Helmet>
                <title>Yacht Zone Market</title>
                <link rel="canonical" href={"http://www.yachtzonemarket.com"} />
                <meta name="description" content="Yacht zone market, yacht parts and marine products based in Dubai, UAE" />
                <meta name="theme-color" content="#008f68" />
                <meta property="og:url" content={"http://www.yachtzonemarket.com"} />
                <meta property="og:title" content="Yacht Zone Market" />
                <meta property="og:description" content="Yacht zone market, yacht parts and marine products based in Dubai, UAE" />
                <meta property="og:image" content="http://www.yachtzonemarket.com/logo192.png" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Yacht Zone Market" />
            </Helmet>
            <div className="home__container">
                <h1 hidden>The Yacht Zone Market</h1>
                <NavMenu />
                <Slideshow />
                <div className="home__rows">
                    {
                        rowdata.length ? rowdata.map((row, index) =>
                            <div key={'home__rows' + index} className='home__row'>
                                <Link to={'/productcategory/' + row.id}>
                                    <div className='home__rowtitle'>{row.title}</div>
                                </Link>
                                <div className='home__rowscolumns'>
                                    {
                                        row.data instanceof Array ? row.data
                                            .map(product =>
                                                <Product
                                                    key={product.id}
                                                    id={product.id}
                                                    name={product.name}
                                                    image={product.images}
                                                    price={product.price}
                                                    currency={product.currencyprice}
                                                />
                                            ) : null
                                    }
                                </div>
                            </div>
                        ) : null
                    }
                </div>
            </div>
        </div>
    )
}

export default Home