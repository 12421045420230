import React from 'react'
import './Checkout.css'
import { useStateValue } from './StateProvider'
import { getBasketTotal } from './Reducer';
import { useNavigate } from 'react-router-dom'

function Checkout() {
    const [{ basket }] = useStateValue();

    return (
        <div className="checkout">
            <div key='checkoutleft' className="checkout__left">
                <div className="checkout__title">
                    <h2>Your Shopping Basket</h2>
                    {
                        basket.map((item, index) => (
                            <CheckoutProduct
                                key={'checkout' + index}
                                planid={item.planid}
                                planname={item.planname}
                                planprice={item.planprice}
                            />
                        ))}
                </div>
            </div>
            <div key='checkoutright' className="checkout__right">
                <Subtotal />
            </div>
        </div>
    )

    function Subtotal() {
        const history = useNavigate();
        const [{ basket, user }] = useStateValue();

        return (
            <div className="subtotal">
                <p>Subtotal ({basket.length} items): <strong>{getBasketTotal(basket)} AED</strong></p>
                <button onClick={e => history(user ? '/payment' : '/login')}>Proceed to Checkout</button>
            </div>
        )
    }
    function CheckoutProduct({ planid, planname, planprice }) {
        const [{ }, dispatch] = useStateValue();

        const removeFromBasket = () => {
            dispatch({
                type: "REMOVE_FROM_BASKET",
                planid: planid,
            });
        }

        return (
            <div className='checkoutProduct'>
                <div className='checkoutProduct__info'>
                    <div key='title' className='checkoutProduct__title'>{planname}</div>
                    <div key='price' className='checkoutProduct__price'><strong>{planprice} AED</strong></div>
                </div>
                <button onClick={removeFromBasket} className='checkoutProduct__removeButton'>Remove from Cart</button>
            </div>
        )
    }
}

export default Checkout