import React from 'react'
import './Product.css'
import noimage from './img/noimage.png';

import { Link } from 'react-router-dom';

function Product({ id, name, image, price, currency }) {
    return (
        <div className='product' id={id} >
            <div className='product__imagewrapper'>
                <Link to={'/product/' + id} >{image ? <img className='product__image' src={image} />:<img className='product__image' src={noimage}/>}</Link>
            </div>
            <div className='product__titlewrapper'>
                <Link to={'/product/' + id} title={name} className='product__title'>{name}</Link>
            </div>
            <div className='product__pricewrapper'>
                <div className='product__price'>{price} {currency}</div>
            </div>
        </div>
    );
}

export default Product