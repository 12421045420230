import React, { useState } from 'react'
import './Register.css'
import { Link } from 'react-router-dom'
import { auth } from './Firebase';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import configData from './Config.json';

function Register() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const history = useNavigate();

    const register = e => {
        e.preventDefault();

        if (!email) {
            setError('Please enter email')
            return
        }
        if (!password) {
            setError('Please enter password')
            return
        }
        if (password !== confirmPassword) {
            setError('Passwords dont match')
            return
        }

        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                console.log('Signed in');

                const user = userCredential.user;

                console.log('Trying to initialize user');
                const data = {
                    userid: user ? user.uid : null
                };
                axios.post(configData.INITIALIZEUSER_URL, data, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                }).then(res => {
                    setError(null);
                    for (var key of Object.keys(res.data)) {
                        if (res.data[key].code !== 1) {
                            setError(error + '/' + res.data[key].data)
                        }
                    }
                    if (!error) history('/')
                })
            })
            .catch((error) => {
                setError(error.message);
            });
    }

    return (
        <div className="register">
            <div className="register__container">
                <h1>Register</h1>
                <form>
                    <h5>E-mail</h5>
                    <input value={email} type='text' onChange={e => setEmail(e.target.value)} />

                    <h5>Password</h5>
                    <input
                        value={password}
                        type='password'
                        onChange={e => setPassword(e.target.value)}
                        autoComplete='off'
                    />

                    <h5>Confirm Password</h5>
                    <input
                        value={confirmPassword}
                        type='password'
                        onChange={e => setConfirmPassword(e.target.value)}
                        autoComplete='off'
                    />
                    {(error !== '') ? (<div className='register__result'>{error}</div>) : null}
                    <button className="register__registerButton" onClick={register}>Create your account</button>
                    <div className="register__terms">
                        By creating an account, you agree to YZM's <Link to="/terms">Terms and Conditions</Link>.
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Register