import React, { useState, useEffect } from 'react'
import './ProductInfo.css'
import { useParams } from "react-router-dom"
import axios from 'axios';
import configData from './Config.json';
import { Helmet } from 'react-helmet';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom'
import { RotateSpinner } from "react-spinners-kit";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ProductInfo = ({ usertype }) => {
    const { id } = useParams();
    const [product, setProduct] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log('Trying to get product data')
        const url = configData.PRODUCT_URL
        const data = {
            productid: id
        };
        axios.post(url, data, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
        }).then(res => {
            console.log('Received product data')
            if(res.data instanceof Object){
                const product = res.data;
                setProduct(product);
            }
            setLoading(false)
        })
    }, []);

    return (
        loading ?
            <div className='spinner'>
                <RotateSpinner size={60} color="#686769" loading={loading} />
            </div>
            :
            <div className='productinfo'>
                <Helmet>
                    <title>{product.name && product.name}</title>
                    <link rel="canonical" href={"http://www.yachtzonemarket.com/productinfo/" + id} />
                    <meta name="description" content={product.description && product.description.slice(0, 170)} />
                    <meta property="og:url" content={"http://www.yachtzonemarket.com/productinfo/" + id} />
                    <meta property="og:title" content={product.name && product.name} />
                    <meta property="og:description" content={product.description && product.description.slice(0, 170)} />
                    <meta property="og:image" content={product.images instanceof Array && product.images.slice(0, 1)} />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content="Yacht Zone Market" />
                </Helmet>
                <h1 className='productinfo__name'>{product && product.name}</h1>
                <div className='productinfo__id'>Product id: #{product.id}</div>
                <Carousel showArrows={true} >
                    {
                        product.images ? product.images.map((item, index) => {
                            return (<div><img className='productinfo__image' src={item} /></div>)
                        }) : null
                    }
                </Carousel>
                <div className="productinfo__description" >
                    <strong>
                        {
                            product.name ? product.name : 'No name'
                        }
                    </strong>
                    <br /><br />
                    {
                        product.description ? product.description : 'No description'
                    }
                </div>
                <div className="productinfo__technical" >
                    <strong>Techincal Details:</strong>
                    <table className='productinfo__technicaltable'>
                        <tbody>
                            {
                                product.technical ? product.technical.map((item, index) => {
                                    return <tr><td>{item[0]}</td><td>{item[1]}</td></tr>;
                                }) : null
                            }
                        </tbody>
                    </table>
                </div>
                <div className="productinfo__condition" >
                    <strong>Condition:</strong><br /><br />
                    {
                        product.condition ? product.condition : 'No condition info'
                    }
                    <br /><br />
                    {
                        product.conditionremarks ? product.conditionremarks : 'No remarks'
                    }
                </div>
                <div className="productinfo__supplier" >
                    <strong>Supplier Info:</strong><br /><br />
                    {
                        usertype ? (product.supplier ? product.supplier : 'No supplier info') : <Link to='/plans'>Please subscribe to see full details</Link>
                    }
                </div>
                <div className="productinfo__allocation" >
                    <strong>Allocation:</strong><br /><br />
                    {
                        usertype ? (product.allocation ? product.allocation : 'No allocation info') : <Link to='/plans'>Please subscribe to see full details</Link>
                    }
                </div>
                <div className="productinfo__price">
                    <strong>Price:</strong><br /><br />
                    <strong>{product.price} {product.currencyprice}</strong>
                </div>
            </div>
    );
};

export default ProductInfo