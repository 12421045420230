import React, { useState } from "react";
import "./Header.css";
import logo from './img/logo.png';
import SearchIcon from '@mui/icons-material/Search';
import Modal from 'react-modal'
import { Link, useNavigate } from 'react-router-dom'
import { useStateValue } from './StateProvider'
import { auth } from "./Firebase";

Modal.setAppElement('#root');

function Header({ mobilemode }) {
    const [{ user }, dispatch] = useStateValue();
    const [search, setSearch] = useState();
    const history = useNavigate();

    const searchHandler = () => {
        dispatch({
            type: "SET_SEARCH",
            search: search,
        });
        history('/search');
    }
    const handleLoginClick = (e) => {
        e.preventDefault();
        if (user) {
            auth.signOut();
            dispatch({
                type: "SET_USER",
                user: null,
            });
        }
        else {
            history('/login');
        }
    }

    return (
        <div className="header">
            <div className='header__logocontainer'>
                <Link to='/'>
                    <img
                        className="header__logo"
                        src={logo}
                        alt="logo"
                    />
                </Link>
            </div>
            {
                !mobilemode &&
                (<div className='header__deliverycontainer'>
                    <Link to="/plans">
                        <div className="header__delivery" >
                            <div className="header__navOption">
                                <span className="header__navOptionLine1">Plans</span>
                                <span className="header__navOptionLine2">& Subscriptions</span>
                            </div>
                        </div>
                    </Link>
                </div>)
            }
            <div className="header__search">
                <input
                    className="header__searchInput"
                    type="text"
                    value={search}
                    onKeyPress={event => {
                        if (event.key === 'Enter') {
                            searchHandler(event)
                        }
                    }
                    }
                    onChange={e => setSearch(e.target.value)}
                />
                <SearchIcon onClick={searchHandler}
                    className="header_searchIcon" />
            </div>
            {
                !mobilemode &&
                <div className="header__nav">
                    {
                        user ?
                            (<Link to={"/dashboard"}>
                                <div className="header__navOption">
                                    <span className="header__navOptionLine1">Dasboard</span>
                                    <span className="header__navOptionLine2">& settings</span>
                                </div>
                            </Link>)
                            : null
                    }
                    <div onClick={handleLoginClick} >
                        <div className="header__navOption">
                            <span className="header__navOptionLine1">Hello {user ? user.username : 'Guest'}</span>
                            <span className="header__navOptionLine2">{user ? 'Sign Out' : 'Sign In'}</span>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Header