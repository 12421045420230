import React, { useState, useEffect } from 'react'
import './Menu.css'
import configData from './Config.json';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useStateValue } from "./StateProvider";

function Menu() {
    const [{ menu }] = useStateValue();
    const [listings, setListings] = useState([]);
    const [toggle1, setToggle1] = useState();
    const [toggle2, setToggle2] = useState();
    const [breadcrumb, setBreadcrumb] = useState('');

    const handleClick1 = ((e, category) => {
        if (toggle1 === e) { setToggle1(false) }
        else setToggle1(e)
    })
    const handleClick2 = ((e, category) => {
        if (toggle2 === e) { setToggle2(false) }
        else setToggle2(e)
        getCategoryData(category)
    })
    const handleClick3 = ((e, category) => {
        getCategoryData(category)
    })

    useEffect(() => {
        console.log('Trying to get category listing 100')
        const data = {
            categoryid: 100
        };
        const url = configData.CATEGORY_URL
        axios
            .post(url, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
            })
            .then(res => {
                setListings(res.data);
                setBreadcrumb('Category');
            })
    }, [])


    function getCategoryData(category) {
        console.log('Trying to get category listing ' + category)
        const data = {
            categoryid: category
        };
        const url = configData.CATEGORY_URL
        axios.post(url, data, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
        }).then(res => {
            setListings(res.data);
            setBreadcrumb('Category');
        })
    }

    return (
        <div className='menu'>
            <div className='menu__panelbreadcrumb'>{breadcrumb ? breadcrumb : 'MAIN MENU'}</div>
            <div className='menu__panelcontainer'>
                <div className='menu__sidemenu'>
                    {
                        menu &&
                        menu.firstcategory &&
                        menu.firstcategory.map((menuitem, index) =>
                        (
                            <div key={'fc' + index} className='menu__firstcategory' >
                                <div className='menu__firstcategorytitle' onClick={() => handleClick1(index)}>{menuitem['@attributes'].name} {menuitem['@attributes'].no ? '(' + menuitem['@attributes'].no + ')' : null}</div>
                                <div className={toggle1 === index ? 'menu__secondcategoryitems show' : 'menu__secondcategoryitems'}>
                                    {
                                        menuitem.secondcategory &&
                                            menuitem.secondcategory instanceof Array ?
                                            menuitem.secondcategory.map(
                                                (menuitem2, index) => (
                                                    <div className='menu__thirdcategory'>
                                                        <div className='menu__secondcategorytitle' onClick={() => handleClick2(index, menuitem2['@attributes'].id)}>{menuitem2['@attributes'].name} {menuitem2['@attributes'].no && '(' + menuitem2['@attributes'].no + ')'}</div>
                                                        <div className={toggle2 === index ? 'menu__thirdcategoryitems show' : 'menu__thirdcategoryitems'}>
                                                            {
                                                                menuitem2.thirdcategory &&
                                                                menuitem2.thirdcategory['@attributes'] &&
                                                                <div className='menu__thirdcategorytitle' onClick={() => handleClick3(index, menuitem2.thirdcategory['@attributes'].id)}>{menuitem2.thirdcategory['@attributes'].name} {menuitem2.thirdcategory['@attributes'].no && '(' + menuitem2.thirdcategory['@attributes'].no + ')'}</div>

                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            ) : <div className='menu__secondcategorytitle' onClick={() => handleClick2(index, menuitem.secondcategory['@attributes'].id)}>{menuitem.secondcategory['@attributes'].name}</div>

                                    }
                                </div>
                            </div>
                        )
                        )
                    }
                </div>
                <div className='menu__panel'>
                    {
                        listings instanceof Array && listings.map(
                            (listing, index) =>
                            (
                                <div key={'listing' + index} className='menu__panellisting'>
                                    <div className='menu__panellistingleft'>
                                        <Link to={'/product/' + listing.id}>
                                            <div className='menu__panelname'>{listing.name}</div>
                                        </Link>
                                    </div>
                                    <div className='menu__panellistingright'>
                                        <img className='menu__panelimage' src={listing.images} />
                                    </div>
                                </div>
                            )
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Menu