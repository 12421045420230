import React, { useState } from 'react'
import './Plans.css'
import { useStateValue } from "./StateProvider";
import individuallogo from './img/individual.jpg';
import captainlogo from './img/captain.jpg';
import corporatelogo from './img/corporate.jpg';
import { useNavigate } from 'react-router-dom'

function Plans() {
    const [{ }, dispatch] = useStateValue();
    const [mode, setMode] = useState(0);
    const guest = ['Free', 'Free'];
    const history = useNavigate();
    const individual = ['25 AED/Month', '225 AED/Year'];
    const captain = ['100 AED/Month', '900 AED/Year'];
    const corporate = ['250 AED/Month', '2250 AED/Year'];
    const plans = [
        { 'planid': 2, 'planname': 'Individual monthly plan', 'planprice': 25 },
        { 'planid': 2, 'planname': 'Individual annual plan', 'planprice': 225 },
        { 'planid': 3, 'planname': 'Captain monthly plan', 'planprice': 100 },
        { 'planid': 3, 'planname': 'Captain yearly plan', 'planprice': 900 },
        { 'planid': 4, 'planname': 'Corporate monthly plan', 'planprice': 250 },
        { 'planid': 4, 'planname': 'Corporate yearly plan', 'planprice': 2250 },
    ];

    const subscribe = (option) => {
        dispatch({
            type: "ADD_TO_BASKET",
            item: {
                planid: plans[option].planid,
                planname: plans[option].planname,
                planprice: Number(plans[option].planprice)
            },
        });
        history('/checkout');
    }

    return (
        <div className='plans'>
            <h1 className='plans__title'>YachtZone Market Sales Plans & Pricing</h1>
            <h2 className='plans__caption'>Compare and contrast YachtZone pricing across different plans and scopes</h2>
            <div className='plans__buttons'>
                <div className={mode ? "plans__button" : "plans__button plans__buttonselected"} onClick={() => setMode(0)}>
                    <span className="plans__buttontext">Monthly</span>
                    <span className="plans__buttoncaption">Regular pricing</span>
                </div>
                <div className={mode ? "plans__button plans__buttonselected" : "plans__button"} onClick={() => setMode(1)}>
                    <span className="plans__buttontext">Annual</span>
                    <span className="plans__buttoncaption">Save up to 25%</span>
                </div>
            </div>
            <div className='plans__columns'>
                <div className='plans__column'>
                    <div className='plans__columntitle'>Guest</div>
                    <div className='plans__columncost'>{guest[mode]}</div>
                    <div className='plans__columnbutton'></div>
                </div>
                <div className='plans__column'>
                    <div className='plans__columntitle'>Individual Plan</div>
                    <img className='plans__columnlogo' src={individuallogo} alt="logo" />
                    <div className='plans__columncost'>{individual[mode]}</div>
                    <div className='plans__columnbutton' onClick={() => subscribe(mode ? 1 : 0)}>Subscribe</div>
                </div>
                <div className='plans__column'>
                    <div className='plans__columntitle'>Captain Plan</div>
                    <img className='plans__columnlogo' src={captainlogo} alt="logo" />
                    <div className='plans__columncost'>{captain[mode]}</div>
                    <div className='plans__columnbutton' onClick={() => subscribe(mode ? 3 : 2)}>Subscribe</div>
                </div>
                <div className='plans__column'>
                    <div className='plans__columntitle'>Corporate Plan</div>
                    <img className='plans__columnlogo' src={corporatelogo} alt="logo" />
                    <div className='plans__columncost'>{corporate[mode]}</div>
                    <div className='plans__columnbutton' onClick={() => subscribe(mode ? 5 : 4)}>Subscribe</div>
                </div>
            </div>
            <div className='plans__comparison'>
                <div className='plans__comparisontabletitle'>Compare YachtZone cost and features by plan</div>
                <table className='plans__comparisontable'>
                    <thead><tr><td></td><td>Guest</td><td>Individual</td><td>Captain</td><td>Corporate</td></tr></thead>
                    <tbody>
                        <tr>
                            <td>Can browse products</td>
                            <td>✔</td>
                            <td>✔</td>
                            <td>✔</td>
                            <td>✔</td>
                        </tr>
                        <tr>
                            <td>Number of allowed product posts</td>
                            <td>0</td>
                            <td>5</td>
                            <td>20</td>
                            <td>Unlimited</td>
                        </tr>
                        <tr>
                            <td>Can see <strong>price</strong> and <strong>supplier</strong> details</td>
                            <td>None</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                        </tr>
                        <tr>
                            <td>Access to YZ Forum for Parts</td>
                            <td></td>
                            <td>✔</td>
                            <td>✔</td>
                            <td>✔</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Plans