import React, { useState, useEffect } from 'react'
import './Search.css'
import configData from './Config.json';
import axios from 'axios';
import NavMenu from './NavMenu'
import { useStateValue } from "./StateProvider";
import { Link } from 'react-router-dom';

function Search() {
    const [{ menu, search, searchcatid, searchcatname }, dispatch] = useStateValue();
    const [results, setResults] = useState([]);

    useEffect(() => {
        console.log('Trying to get search results')
        const url = configData.SEARCH_URL
        const data = {
            search: search,
            searchcatid: searchcatid
        };
        axios
            .post(url, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
            })
            .then(res => {
                console.log('Search result received');
                setResults(res.data.sort((a, b) => (a.percent < b.percent) ? 1 : -1));
            })
    }, [search, searchcatid]);

    const handleCategory = (catid, catname) => {
        dispatch({
            type: "SET_SEARCH",
            search: search,
            searchcatname: catname,
            searchcatid: catid,
        });
    }

    const openAllCategories = (e) => {
        e.preventDefault();
        var element = document.getElementsByClassName('search__navcatitem');
        for (var i = 0; i < element.length; i++) {
            element[i].style.display = 'block';
        }
        e.target.style.display = 'none'
    }

    return (
        <div className="search">
            <NavMenu />
            <div className='search__header'>Showing results for "{search}"{searchcatname && ' in "' + searchcatname + '"'}</div>
            <div className="search__container">
                <div className='search__nav'>
                    <div className='search__navtitle'>Category</div>
                    <div className='search__navcat'>
                    <div onClick={() => handleCategory(0, 'All')} className='search__navcatitem'>All</div>
                        {
                            menu &&
                            menu.firstcategory &&
                            menu.firstcategory.map((menuitem, index) =>
                            (
                                index > 7
                                    ? <div onClick={() => handleCategory(menuitem['@attributes'].id, menuitem['@attributes'].name)} style={{ display: 'none' }} className='search__navcatitem' >{menuitem['@attributes'].name}</div> :
                                    <div onClick={() => handleCategory(menuitem['@attributes'].id, menuitem['@attributes'].name)} className='search__navcatitem' >{menuitem['@attributes'].name}</div>
                            )
                            )
                        }
                        {
                            menu &&
                            menu.firstcategory.length > 7 && <div onClick={(e) => openAllCategories(e)} className='search__navcatseemore' >See All Categories</div>
                        }
                    </div>
                </div>
                <div className='search__result'>
                    <div className="search__resulttitle">RESULTS</div>
                    <ul className="search__resultpanel">
                        {
                            results && results.length ? (
                                results instanceof Array && results.map((result, index) =>
                                <li key={'search__resultitem' + index} className='search__resultitem'>
                                    <Link to={'/product/' + result.id}>
                                        <div className='search__resultitemimagecontainer'>
                                            <img className='search__resultitemimage' src={result.image} />
                                        </div>
                                        <div className='search__resultitemname'>{result.name}</div>
                                        <div className='search__resultitemprice'>{result.price} {result.pricecurrency}</div>
                                    </Link>
                                </li>
                                )
                            ) : (<div className='search__resultpanelnoresults'>No results found</div>)
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Search