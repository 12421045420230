import React, { useState } from 'react'
import './ChangePassword.css'
import { updatePassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom'
import { auth } from "./Firebase";

function ChangePassword() {
    const [oldpassword, setOldpassword] = useState('');
    const [newpassword, setNewpassword] = useState('');
    const [confirmnewpassword, setConfirmnewpassword] = useState('');
    const [error, setError] = useState('');
    const history = useNavigate();

    const changePassword = e => {
        e.preventDefault();

        if (!oldpassword) {
            setError('Old password not set')
            return
        }
        if (!newpassword) {
            setError('New Password not set')
            return
        }
        if (newpassword !== confirmnewpassword) {
            setError('Passwords dont match')
            return
        }

        const user = auth.currentUser;

        updatePassword(user, newpassword).then(() => {
            history('/');
        }).catch((error) => {
            setError(JSON.stringify(error))
        });

    }

    return (
        <div className="changepassword">
            <div className="changepassword__container">
                <h1>Change Password</h1>
                <form>
                    <h5>Old Password</h5>
                    <input
                        value={oldpassword}
                        type='password'
                        onChange={e => setOldpassword(e.target.value)}
                        autoComplete='off'
                    />
                    <h5>New Password</h5>
                    <input
                        value={newpassword}
                        type='password'
                        onChange={e => setNewpassword(e.target.value)}
                        autoComplete='off'
                    />
                    <h5>Confirm New Password</h5>
                    <input
                        value={confirmnewpassword}
                        type='password'
                        onChange={e => setConfirmnewpassword(e.target.value)}
                        autoComplete='off'
                    />
                    {(error !== '') ? (<div className='changepassword__error'>{error}</div>) : null}
                    <button className="changepassword__changepasswordButton" onClick={changePassword}>Create your account</button>
                </form>
            </div>
        </div>
    );
}

export default ChangePassword