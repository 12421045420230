import React from 'react'
import './Terms.css'

function Terms() {
    return (
        <div className='terms'>
            <p>Last updated: March 31, 2022.</p>
            <p>The yacht zone Fzco. is not responsible or liable for any purchase or sell of second hand or brand-new merchandise through its website www.yachtzonemarket.com, and is not responsible or liable to any goods which are damaged, in a very bad condition, or stolen goods, or theft or misleading advertisements. We do our best to ensure that information on the Site is complete, accurate and current. Despite our efforts, however, information on the Site may occasionally be inaccurate, incomplete or out of date, this is strictly related and directed to the person displaying the goods.
                Prices of products are strictly posted by the seller, and not accompanied by the yacht zone Fzco.
                www.yachtzonemarket.com is strictly and professionally operating as a marketing platform for marine accessories and products, brand new and second-hand parts.
                it is up to the buyer to contact the owner of the parts directly and discuss the quality, quantity and usage of every item, payment process or deal shall be made between buyer and seller and not accompanied the yacht zone fzco by any means.
                we at the yacht zone fzco highly recommend to visually and physically inspect on any merchandise before purchasing it from buyer to ensure the product is what you’re looking for and fits your need, if you cannot attend physically we advise to hire a professional third party surveyor to have the inspection done.
            </p>
        </div>
    )
}

export default Terms