import React, { useState, useEffect } from 'react'
import configData from './Config.json';
import axios from 'axios';
import 'react-upload-gallery/dist/style.css';
import { useStateValue } from "./StateProvider";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import './AllProducts.css'

function AllProducts() {
    const [allproducts, setAllproducts] = useState([]);
    const [{ user }] = useStateValue();

    useEffect(() => {
        console.log('Trying to get list');
        const url = configData.LISTALLPRODUCTS_URL
        const data = {
            userid: user ? user.uid : null
        };
        axios
            .post(url, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
            })
            .then(res => {
                console.log('Received list data');
                const allproducts = res.data;
                if (allproducts instanceof Array) {
                    setAllproducts(allproducts);
                }
            })
    }, [])


    return (
        <div className='allproducts'>
            <div className='allproducts__title'>All Products</div>
            {
                allproducts.length ?
                    (
                        <table className='allproducts__table' id="table-to-xls">
                            <thead><tr><th>Id</th><th>Date</th><th>CategoryId</th><th>CategoryName</th><th>Name</th><th>Quantity</th><th>Allocation</th><th>Cost</th><th>CurrencyCost</th><th>Price</th><th>CurrencyPrice</th><th>Status</th></tr></thead>
                            <tbody>
                                {
                                    allproducts.map((product, index) => {
                                        return <>
                                            <tr key={'tr' + index}>
                                                <td>{product.id}</td>
                                                <td>{new Date(product.ts * 1000).toLocaleDateString("en-US")}</td>
                                                <td>{product.category}</td>
                                                <td>{product.categoryname}</td>
                                                <td>{product.name}</td>
                                                <td>{product.quantity}</td>
                                                <td>{product.allocation}</td>
                                                <td>{product.cost}</td>
                                                <td>{product.currencycost}</td>
                                                <td>{product.price}</td>
                                                <td>{product.currencyprice}</td>
                                                <td>{product.status}</td>
                                            </tr>
                                        </>;
                                    })
                                }
                            </tbody>
                        </table>
                    )
                    : (<div className="allproducts__nodata">No Data</div>)
            }
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="allproducts__downloadbutton"
                table="table-to-xls"
                filename="tablexls"
                sheet="tablexls"
                buttonText="Download as XLS" />
        </div>
    )
}

export default AllProducts