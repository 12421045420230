export const initialState = {
  basket: [],
  user: null
};

// Selector
export const getBasketTotal = (basket) =>
  basket?.reduce((amount, item) => item.planprice + amount, 0);

const reducer = (state, action) => {
  switch (action.type) {

    case "SET_USER":
      return {
        ...state,
        user: action.user
      }

    case "SET_DASHSETTINGS":
      return {
        ...state,
        currency: action.currency,
        usertype: action.usertype,
        usertypename: action.usertypename,
        usertypeproductslimit: action.usertypeproductslimit
      }
    case "ADD_TO_BASKET":
      return {
        ...state,
        basket: [action.item],
      };
    case 'EMPTY_BASKET':
      return {
        ...state,
        basket: []
      }
    case "REMOVE_FROM_BASKET":
      const index = state.basket.findIndex(
        (basketItem) => basketItem.planid === action.planid
      );
      let newBasket = [...state.basket];

      if (index >= 0) {
        newBasket.splice(index, 1);
      }
      else {
        console.warn(
          'Cant remove product (id: ${action.id}) as its not in basket!'
        )
      }
      return {
        ...state,
        basket: newBasket
      }
    case "SET_MENU":
      return {
        ...state,
        menu: action.menu,
      }
    case "SET_SEARCH":
      return {
        ...state,
        search: action.search,
        searchcatname: action.searchcatname,
        searchcatid: action.searchcatid
      }

    default:
      return state;
  }
};

export default reducer;
