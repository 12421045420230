import { useState, useEffect } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import './CheckoutForm.css'
import { useStateValue } from './StateProvider';
import { useNavigate } from 'react-router-dom';
import configData from './Config.json';
import axios from 'axios';
import { getBasketTotal } from './Reducer';

const CheckoutForm = () => {
  const [{ basket, user }, dispatch] = useStateValue();
  const history = useNavigate();

  const [succeeded, setSucceeded] = useState(false)
  const [processing, setProcessing] = useState("")
  const [disabled, setDisabled] = useState(true)
  const [clientSecret, setClientSecret] = useState(true)
  const stripe = useStripe();
  const elements = useElements();
  const [result, setResult] = useState(null);

  useEffect(() => {
    const url = configData.STRIPEKEY_URL
    const data = {
      basket: basket
    };
    axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then(res => {
        setClientSecret(res.data.clientSecret);
      })
  }, [])

  const handleSubmit = async event => {
    event.preventDefault();
    setProcessing(true)

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)
      }
    }).then(({ paymentIntent }) => {
      console.log('Trying to save payment to database');
      const data = {
        userid: user.uid,
        basket: basket,
        amount: paymentIntent.amount,
        created: paymentIntent.created
      };
      axios
        .post(configData.PAYMENT_URL, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then(res => {
          console.log("Saved to database");

          if (res.data.code == 1) {
            setSucceeded(true);
            setResult(null);
            setProcessing(false)
            dispatch({
              type: "EMPTY_BASKET",
            })
            history("/dashboard")
          }
          else {
            setResult(res.data.data)
          }
        });
    })

  }

  const handleChange = event => {
    setDisabled(event.empty)
    setResult(event.error ? event.error.message : "")
  }

  return (
    <div className="payment">
      <div className='payment__title'>
        Checkout ({basket?.length} {basket?.length > 1 ? 'items' : 'item'})
      </div>
      <div className='payment__section'>
        <div className="payment__left">
          <strong>Total price </strong>
        </div>
        <div className="payment__right">
          {getBasketTotal(basket)} AED
        </div>
      </div>
      <div className="payment__section">
        <div className="payment__left">
        </div>
        <div className="payment__right">
          <div className="payment__details">
            <form onSubmit={handleSubmit} >
              <CardElement onChange={handleChange} />
              <div className="payment__priceContainer">
                <button disabled={processing || disabled || succeeded}>
                  <span>{processing ? <p>Processing</p> : "Buy Now"} </span>
                </button>
              </div>
              {result && <div>{result}</div>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;