import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './Dashboard.css'
import { useStateValue } from "./StateProvider";
import AvatarImageCropper from 'react-avatar-image-cropper';
import defaultAvatar from './img/defaultavatar.jpg';
import configData from './Config.json';
import axios from 'axios';
import { RotateSpinner } from "react-spinners-kit";
import RUG from 'react-upload-gallery';
import 'react-upload-gallery/dist/style.css';
import Modal from 'react-modal';
import { auth } from "./Firebase";
import { deleteUser } from "firebase/auth";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center'
    },
};

function Dashboard() {
    const [{ menu, currency, user, usertype, usertypename }, dispatch] = useStateValue();
    const [option, setOption] = useState(<AddProduct />);
    const history = useNavigate();

    useEffect(() => {
        console.log('Trying to get dash settings');
        const data = {
            userid: user ? user.uid : null
        };
        axios
            .post(configData.DASHSETTINGS_URL, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
            })
            .then(res => {
                console.log('Received dash Settings');
                if (res.data instanceof Object) {
                    dispatch({
                        type: "SET_DASHSETTINGS",
                        currency: res.data.currency,
                        usertype: parseInt(res.data.usertype),
                        usertypename: res.data.usertypename,
                        usertypeproductslimit: res.data.usertypeproductslimit,
                    });
                }
            })
    }, [])


    return (
        <div className='dashboard'>
            <div className='dashboard__sidemenu'>
                <ul>
                    <li className='dashboard__sidemenuitem' onClick={() => setOption(<MyProfile />)}>Profile Settings</li>
                    <li className='dashboard__sidemenuitem' onClick={() => setOption(<AddProduct />)}>Add New Product</li>
                    <li className='dashboard__sidemenuitem' onClick={() => setOption(<MyProducts />)}>My Products</li>
                    <li className='dashboard__sidemenuitem' onClick={() => setOption(<Sales />)}>Sales</li>
                    <li className='dashboard__sidemenuitem' onClick={() => setOption(<ListSales />)}>List Sales</li>
                    {usertype === 1 ? <li className='dashboard__menuitemadmin' onClick={() => setOption(<Admin />)}>Admin</li> : null}
                    {usertype === 1 ? <li className='dashboard__menuitemadmin' onClick={() => setOption(<MenuEditor />)}>Menu Editor</li> : null}
                    {usertype === 1 ? <li className='dashboard__menuitemadmin' onClick={() => setOption(<ManageProducts />)}>Manage Products</li> : null}
                    {usertype === 1 ? <li className='dashboard__menuitemadmin' onClick={() => setOption(<Subscriptions />)}>Subscriptions</li> : null}
                </ul>
            </div>
            <div className='dashboard__panel'>
                {option}
            </div>
        </div>
    )
    function MyProfile() {
        const [preview, setPreview] = useState();
        const [imagetoupload, setImageToUpload] = useState();
        const [name, setName] = useState('');
        const [username, setUsername] = useState('');
        const [company, setCompany] = useState('');
        const [email, setEmail] = useState('');
        const [number, setNumber] = useState('');
        const [address, setAddress] = useState('');
        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        useEffect(() => {
            console.log('Trying to get profile');
            const url = configData.GETMYPROFILE_URL
            const data = {
                userid: user ? user.uid : null
            };
            axios
                .post(url, data, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                })
                .then(res => {
                    console.log('Received profile data');
                    const profile = res.data;
                    if (profile instanceof Object) {
                        setPreview(profile.profilepic);
                        setName(profile.name);
                        setUsername(profile.username);
                        setCompany(profile.company);
                        setEmail(profile.email);
                        setNumber(profile.contactnumber);
                        setAddress(profile.contactaddress);
                    }
                    else {
                        setResult('Could not get data')
                    }
                })
        }, [])

        const handleSubmit = e => {
            e.preventDefault();
            setLoading(true);

            console.log('Trying to submit product');
            const myprofileFormData = new FormData();
            myprofileFormData.append("userid", user ? user.uid : null);
            myprofileFormData.append("file", imagetoupload);
            myprofileFormData.append("name", name);
            myprofileFormData.append("company", company);
            myprofileFormData.append("email", email);
            myprofileFormData.append("username", username);
            myprofileFormData.append("number", number);
            myprofileFormData.append("address", address);

            axios
                .post(configData.SAVEMYPROFILE_URL, myprofileFormData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                })
                .then(res => {
                    setResult(JSON.stringify(res.data));
                    setLoading(false)
                })
        }
        const applyCrop = (file) => {
            setPreview(window.URL.createObjectURL(file));
            setImageToUpload(file);
        }
        const deleteAvatar = () => {
            console.log('Trying to delete avatar');
            const url = configData.DELETEAVATAR_URL
            const data = {
                userid: user ? user.uid : null
            };
            axios
                .post(url, data, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                })
                .then(res => {
                    setResult(JSON.stringify(res.data));
                    setPreview(defaultAvatar)
                })
        }
        const deleteMyProfile = () => {
            const user = auth.currentUser;
            deleteUser(user).then(() => {
                console.log('Trying to delete my profile');
                const url = configData.DELETEMYPROFILE_URL
                const data = {
                    userid: user ? user.uid : null
                };
                axios
                    .post(url, data, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                    })
                    .then(res => {
                        setResult(null);
                        for (var key of Object.keys(res.data)) {
                            if (res.data[key].code != 1) {
                                setResult(result + '/' + res.data[key].data)
                            }
                        }
                        if (!result) {
                            auth.signOut();
                            dispatch({
                                type: "SET_USER",
                                user: null,
                            });
                            history('/');
                        }
                    })
            }).catch((error) => {
                setResult(JSON.stringify(error));
            });
        }
        const changePassword = () => {
            history('/changepassword')
        }
        return (
            <div className='myprofile'>
                <h1 className='dashboard__title'>Profile Settings</h1>
                <div className='dashboard__body'>
                    <div className='myprofile__avatar'>
                        <div style={{ backgroundImage: `url(${preview ? preview : null})`, width: '250px', height: '250px', margin: 'auto' }}>
                            <AvatarImageCropper apply={applyCrop} maxsize={1024 * 1024 * 5} isBack={true} text=' ' icon='' />
                        </div>
                        <button onClick={deleteAvatar}>Delete</button>
                    </div>
                    <label htmlFor="fullName">Full Name</label>
                    <input id="fullName" placeholder="Enter Full Name" defaultValue={name} onChange={e => setName(e.target.value)} />
                    <label htmlFor="username">Username</label>
                    <input id="username" placeholder="Enter Username" defaultValue={username} onChange={e => setUsername(e.target.value)} />
                    <label htmlFor="companyInfo">Company Name</label>
                    <input id="companyInfo" placeholder="Enter Company Info" defaultValue={company} onChange={e => setCompany(e.target.value)} />
                    <label htmlFor="emaillAddress">Email Address</label>
                    <input disabled id="emaillAddress" placeholder="Email Address" defaultValue={user ? user.email : null} />
                    <label htmlFor="contactNumber">Contact Number</label>
                    <input id="contactNumber" placeholder="Contact Number" defaultValue={number} onChange={e => setNumber(e.target.value)} />
                    <label htmlFor="contactAddress">Contact Address</label>
                    <input id="contactAddress" placeholder="Contact Address Info" defaultValue={address} onChange={e => setAddress(e.target.value)} />
                    <div className='editproduct__spinner'>
                        <RotateSpinner size={30} color="#686769" loading={loading} />
                    </div>
                    {(result !== '') ? (<div className='dashboard__status'>{result}</div>) : null}
                    <button onClick={handleSubmit}>Save Settings</button>
                    <button onClick={changePassword}>Change Password</button>
                    <button onClick={deleteMyProfile}>Delete Account</button>
                </div>
            </div>
        )
    }
    function AddProduct() {
        const [imagesToAdd, setImagesToAdd] = useState([]);
        const [name, setName] = useState('');
        const [description, setDescription] = useState('');
        const [condition, setCondition] = useState(0);
        const [conditionremarks, setConditionremarks] = useState('');
        const [category, setCategory] = useState('');
        const [cost, setCost] = useState(0);
        const [currencyCost, setCurrencyCost] = useState();
        const [price, setPrice] = useState(0);
        const [currencyPrice, setCurrencyPrice] = useState();
        const [quantity, setQuantity] = useState(0);
        const [tkey, setTkey] = useState('');
        const [tdata, setTdata] = useState('');
        const [technical, setTechnical] = useState([]);
        const [keyword, setKeyword] = useState('');
        const [keywords, setKeywords] = useState([]);
        const [allocation, setAllocation] = useState('');
        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        const handleSubmit = e => {
            e.preventDefault();
            console.log('Trying to submit product');

            setLoading(true);

            if (!user) {
                setResult('User not logged in');
                setLoading(false)
                return
            }
            if (!name) {
                setResult('Name Missing');
                setLoading(false)
                return
            }
            if (!description) {
                setResult('Description Missing');
                setLoading(false)
                return
            }

            const addproductFormData = new FormData();

            for (let i = 0; i < imagesToAdd.length; i++) {
                addproductFormData.append("file" + i, imagesToAdd[i].file);
            }
            addproductFormData.append("userid", user ? user.uid : '');
            addproductFormData.append("name", name);
            addproductFormData.append("description", description);
            addproductFormData.append("condition", condition);
            addproductFormData.append("conditionremarks", conditionremarks);
            addproductFormData.append("category", category);
            addproductFormData.append("cost", cost);
            addproductFormData.append("price", price);
            addproductFormData.append("currencycost", currencyCost);
            addproductFormData.append("currencyprice", currencyPrice);
            addproductFormData.append("quantity", quantity);
            addproductFormData.append("allocation", allocation);
            addproductFormData.append("technical", JSON.stringify(technical));
            addproductFormData.append("keywords", JSON.stringify(keywords));

            axios
                .post(configData.ADDPRODUCT_URL, addproductFormData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                })
                .then(res => {
                    setResult(JSON.stringify(res.data));
                    setLoading(false)
                })
        }
        const removeKeyword = name => {
            console.log('Deleting keyword')
            setKeywords(keywords.filter(keyword => keyword !== name))
        }
        const removeTechnical = key => {
            console.log('Deleting technical')
            setTechnical(technical.filter(
                (item, itemIndex) => key !== itemIndex
            ))
        }
        const addKeyword = () => {
            if (keyword) setKeywords([...keywords, keyword]);
            setKeyword('')
        }
        const addTechnical = () => {
            if (tkey && tdata) setTechnical(technical => [...technical, [tkey, tdata]])
            setTdata('');
            setTkey('');
        }

        return (
            <div className='addproduct'>
                <h1 className='dashboard__title'>Add Product</h1>
                <div className='dashboard__body'>
                    <RUG
                        source={response => response.source}
                        onChange={(images) => {
                            setImagesToAdd(images)
                        }}
                    />
                    <label>Product Name</label>
                    <input placeholder="Name" defaultValue={name} onChange={e => setName(e.target.value)} />
                    <label>Product Description</label>
                    <textarea rows='7' placeholder="Description" defaultValue={description} onChange={e => setDescription(e.target.value)} />
                    <label>Product Condition</label>
                    <select placeholder="Condition" value={condition} onChange={e => setCondition(e.target.value)}>
                        <option key='addproduct__condition1' value='0'>Mint</option>
                        <option key='addproduct__condition2' value='1'>Good</option>
                        <option key='addproduct__condition3' value='2'>Fair</option>
                        <option key='addproduct__condition4' value='3'>Poor</option>
                    </select>
                    <label>Condition Remarks</label>
                    <textarea rows='3' placeholder="Condition remarks" defaultValue={conditionremarks} onChange={e => setConditionremarks(e.target.value)} />
                    <label>Product Category</label>
                    <select placeholder="Category" value={category} onChange={e => setCategory(e.target.value)}  >
                        {
                            menu && menu.firstcategory ? menu.firstcategory.map((category1, index) => {
                                return (
                                    <>
                                        <option disabled key={'category1option' + index} className='product__categoryoption1' value={category1['@attributes'].id}>
                                            {category1['@attributes'].id + ' ' + category1['@attributes'].name}
                                        </option>
                                        {
                                            category1.secondcategory && category1.secondcategory instanceof Array ? category1.secondcategory.map((category2, index2) => {
                                                return (
                                                    <>
                                                        <option key={'category2option' + index2} className='product__categoryoption2' value={category2['@attributes'].id}>
                                                            {'\xA0\xA0' + category2['@attributes'].id + ' ' + category2['@attributes'].name}
                                                        </option>
                                                        {
                                                            category2.thirdcategory && category2.thirdcategory instanceof Array ? category2.thirdcategory.map((category3, index3) => {
                                                                return (
                                                                    <option key={'category3option' + index3} className='product__categoryoption3' value={category3['@attributes'].id}>
                                                                        {'\xA0\xA0\xA0\xA0' + category3['@attributes'].id + ' ' + category3['@attributes'].name}
                                                                    </option>
                                                                )
                                                            }) : category2.thirdcategory instanceof Object &&
                                                            <option key={'category3option1'} className='product__categoryoption3' value={category2.thirdcategory['@attributes'].id}>
                                                                {'\xA0\xA0' + category2.thirdcategory['@attributes'].id + ' ' + category2.thirdcategory['@attributes'].name}
                                                            </option>
                                                        }
                                                    </>
                                                )
                                            }) : category1.secondcategory instanceof Object &&
                                            <option key={'category2option1'} className='product__categoryoption2' value={category1.secondcategory['@attributes'].id}>
                                                {'\xA0\xA0' + category1.secondcategory['@attributes'].id + ' ' + category1.secondcategory['@attributes'].name}
                                            </option>
                                        }
                                    </>
                                );
                            }) : <option>List not received</option>
                        }
                    </select>
                    <label>Product Cost</label>
                    <div className='dashboard__inputgroup'>
                        <input placeholder="Cost" defaultValue={cost} onChange={e => setCost(e.target.value)} />
                        <select value={currencyCost} onChange={e => setCurrencyCost(e.target.value)} >
                            {
                                currency instanceof Array ? currency.map((curr, index) => {
                                    return (
                                        <option key={'option' + index} value={index}>{curr.currency}</option>
                                    );
                                }) : <option>List not received</option>
                            }
                        </select>
                    </div>
                    <label>Product Price</label>
                    <div className='dashboard__inputgroup'>
                        <input placeholder="Price" defaultValue={price} onChange={e => setPrice(e.target.value)} />
                        <select value={currencyPrice} onChange={e => setCurrencyPrice(e.target.value)} >
                            {
                                currency instanceof Array ? currency.map((curr, index) => {
                                    return (
                                        <option key={'option' + index} value={index}>{curr.currency}</option>
                                    );
                                }) : <option>List not received</option>
                            }
                        </select>
                    </div>
                    <label >Product Quantity</label>
                    <input placeholder="Quantity" defaultValue={quantity} onChange={e => setQuantity(e.target.value)} />
                    <label>Allocation:</label>
                    <input placeholder="Allocation (ex: Country-Warehouse-Shelf no.)" defaultValue={allocation} onChange={e => setAllocation(e.target.value)} />
                    <label>Product Technical Details</label>
                    <input placeholder="Key" defaultValue={tkey} onChange={e => setTkey(e.target.value)} />
                    <input placeholder="Data" defaultValue={tdata} onChange={e => setTdata(e.target.value)} />
                    <button onClick={addTechnical}>Add Technical Detail</button>
                    <div className='addproduct__previewtechnical'>
                        {
                            technical instanceof Array && technical.length ?
                                (
                                    <table className='dashboard__table'>
                                        <tbody>
                                            {
                                                technical.map((technical, index) => {
                                                    return (
                                                        <tr key={'tr' + index}>
                                                            <td key={'tdleft' + index}>{technical[0]}</td>
                                                            <td key={'tdright' + index}>{technical[1]}</td>
                                                            <td key={'tddelete' + index}><span onClick={() => removeTechnical(index)}>x</span></td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                ) : null
                        }
                    </div>
                    <label>Product Keywords</label>
                    <input placeholder="Keywords" defaultValue={keyword} onChange={e => setKeyword(e.target.value)} />
                    <button onClick={addKeyword}>Add Keyword</button>
                    <div className='addproduct__previewkeywords'>
                        {
                            keywords.map((keyword) => {
                                return <>
                                    <div className='addproduct__keyword'>{keyword}</div>
                                    <span onClick={() => removeKeyword(keyword)}>x</span>
                                </>;
                            })
                        }
                    </div>
                    <div className='addproduct__result'>
                        {result ? JSON.stringify(result) : null}
                    </div>
                    <div className='addproduct__spinner'>
                        <RotateSpinner size={30} color="#686769" loading={loading} />
                    </div>
                    <button onClick={handleSubmit}>Save</button>
                </div>
            </div>
        )
    }

    function MyProducts() {
        const [myproductsoption, setMyproductsoption] = useState(<ListMyProducts />);

        function ListMyProducts() {
            const [{ usertypeproductslimit }] = useStateValue();
            const [myproducts, setMyProducts] = useState([]);
            const [searchproduct, setSearchProduct] = useState();

            useEffect(() => {
                console.log('Trying to get list');
                const url = configData.LISTMYPRODUCTS_URL
                const data = {
                    userid: user ? user.uid : null
                };
                axios
                    .post(url, data, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                    })
                    .then(res => {
                        console.log('Received list');
                        const myproducts = res.data;
                        if (myproducts instanceof Array) {
                            setMyProducts(myproducts);
                        }
                        else {
                            setMyProducts([]);
                        }
                    })
            }, [])

            const handleEdit = (e) => {
                setOption(<EditProduct productid={e} />)
            }
            const handleMark = (productid, markid, index) => {
                console.log('Trying to mark property');
                const data = {
                    productid: productid,
                    markid: markid
                };
                axios
                    .post(configData.MARKPRODUCT_URL, data, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                    })
                    .then(res => {
                        const markArray = res.data;
                        console.log(res.data)
                        if (markArray instanceof Object) {
                            console.log('Received my posts');
                            let newArr = [...myproducts];
                            newArr[index].status = markArray.mark;
                            setMyProducts(newArr)
                        }
                    })
            }
            const searchHandler = (e) => {
                e.preventDefault();

                console.log('Trying to get search result');
                const data = {
                    userid: user ? user.uid : null,
                    searchstring: e.target.value
                };
                axios
                    .post(configData.SEARCHMYPRODUCTS_URL, data, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                    })
                    .then(res => {
                        console.log(res.data)
                        if (res.data instanceof Array) {
                            setMyProducts(res.data)
                        }
                    })
            }

            return (
                <div className='myproducts'>
                    <div className='dashboard__body'>
                        <div className='dashboard__status'>{myproducts.length}/{usertypeproductslimit} products</div>
                        <input
                            type="text"
                            value={searchproduct}
                            placeholder='Search by ID or Name'
                            onKeyPress={
                                event => {
                                    if (event.key === 'Enter') {
                                        searchHandler(event)
                                    }
                                }
                            }
                            onChange={e => setSearchProduct(e.target.value)}
                        />
                        <table className='dashboard__table'>
                            <thead><tr><th>Id</th><th>Ts</th><th>Cat</th><th>Name</th><th>Status</th><th>Action</th></tr></thead>
                            {
                                myproducts.length ?
                                    (
                                        <tbody>
                                            {
                                                myproducts.map((product, index) => {
                                                    return (
                                                        <tr key={'tr' + index}>
                                                            <td>{product.id}</td>
                                                            <td>{new Date(product.ts * 1000).toLocaleDateString("en-US")}</td>
                                                            <td>{product.category}</td>
                                                            <td><div className='dashboard__tablelink' onClick={() => handleEdit(product.id)}>{product.name}</div></td>
                                                            <td>{product.status}</td>
                                                            <td>
                                                                <select defaultValue={product.statusid} onChange={(e) => handleMark(product.id, e.target.value, index)}>
                                                                    <option value={1}>Active</option>
                                                                    <option value={3}>Inactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>

                                    )
                                    : null
                            }
                        </table>
                        <Link to="/myproducts">
                            <button className='myproducts__excelbutton'>
                                My Products Excel
                            </button>
                        </Link>
                    </div>
                </div>
            )
        }

        function IncomeStatement() {
            const [{ currency }] = useStateValue();
            const [selectedcurrency, setSelectedcurrency] = useState(0);
            const [incomestatement, setIncomestatement] = useState([]);

            useEffect(() => {
                console.log('Trying to get list');
                const url = configData.INCOMESTATEMENT_URL
                const data = {
                    userid: user ? user.uid : null,
                    currency: selectedcurrency
                };
                axios
                    .post(url, data, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                    })
                    .then(res => {
                        console.log('Received income statement data');
                        if (res.data instanceof Object) {
                            setIncomestatement(res.data);
                        }
                    })
            }, [])

            const selectCurrency = (e) => {
                setSelectedcurrency(e)
                console.log('Trying to get income statement');
                const data = {
                    userid: user ? user.uid : null,
                    currency: selectedcurrency
                };
                axios
                    .post(configData.INCOMESTATEMENT_URL, data, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                    })
                    .then(res => {
                        console.log('Received income statement data');
                        if (res.data instanceof Object) {
                            setIncomestatement(res.data)
                        }
                    })
            }

            return (
                <div className='dashboard__body'>
                    <div className='dashboard__title'>Income Statement</div>
                    <select defaultValue='0' value={selectedcurrency} onChange={e => selectCurrency(e.target.value)} >
                        {
                            currency instanceof Array ? currency.map((curr, index) => {
                                return (
                                    <option value={index}>{curr.currency}</option>
                                );
                            }) : <option>List not received</option>
                        }
                    </select>
                    <table className='dashboard__table'>
                        <tbody>
                            <tr><td>Net Sales</td><td>{incomestatement.netsales}</td><th>{currency[selectedcurrency] && currency[selectedcurrency].currencySymbol}</th></tr>
                            <tr><td>Cost of items sold</td><td>{incomestatement.costofgoodssold}</td><th>{currency[selectedcurrency] && currency[selectedcurrency].currencySymbol}</th></tr>
                            <tr><td>Net Income</td><td>{incomestatement.netincome}</td><th>{currency[selectedcurrency] && currency[selectedcurrency].currencySymbol}</th></tr>
                        </tbody>
                    </table>
                </div>
            )
        }
        function AssetsReport() {
            const [{ currency }] = useStateValue();
            const [selectedcurrency, setSelectedcurrency] = useState(0);
            const [assetsreport, setAssetsreport] = useState([]);

            useEffect(() => {
                console.log('Trying to get list');
                const url = configData.ASSETSREPORT_URL
                const data = {
                    userid: user ? user.uid : null,
                    currency: selectedcurrency
                };
                axios
                    .post(url, data, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                    })
                    .then(res => {
                        console.log('Received assets report data');
                        if (res.data instanceof Object) {
                            setAssetsreport(res.data);
                        }
                    })
            }, [])

            const selectCurrency = (e) => {
                setSelectedcurrency(e)
                console.log('Trying to get assets report');
                const data = {
                    userid: user ? user.uid : null,
                    currency: selectedcurrency
                };
                axios
                    .post(configData.ASSETSREPORT_URL, data, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                    })
                    .then(res => {
                        console.log(res.data)
                        console.log('Received assets report data');
                        if (res.data instanceof Object) {
                            setAssetsreport(res.data)
                        }
                    })
            }

            return (
                <div className='dashboard__body'>
                    <div className='dashboard__title'>Assets Report</div>
                    <select defaultValue='0' value={selectedcurrency} onChange={e => selectCurrency(e.target.value)} >
                        {
                            currency instanceof Array ? currency.map((curr, index) => {
                                return (
                                    <option value={index}>{curr.currency}</option>
                                );
                            }) : <option>List not received</option>
                        }
                    </select>
                    <table className='dashboard__table'>
                        <tbody>
                            <tr><td>Total assets</td><td>{assetsreport.totalassets}</td><th>{currency[selectedcurrency] && currency[selectedcurrency].currencySymbol}</th></tr>
                        </tbody>
                    </table>
                </div>
            )
        }
        return (
            <div className='myproducts'>
                <h1 className='dashboard__title'>My Products</h1>
                <div className='admin__navmenu'>
                    <div className='admin__menuitem' onClick={() => setMyproductsoption(<ListMyProducts />)}>List Products</div>
                    <div className='admin__menuitem' onClick={() => setMyproductsoption(<IncomeStatement />)}>Income Statement</div>
                    <div className='admin__menuitem' onClick={() => setMyproductsoption(<AssetsReport />)}>Assets Report</div>
                </div>
                <div className='admin__panel'>{myproductsoption}</div>
            </div>
        )
    }
    function Sales() {
        const [myproducts, setMyproducts] = useState([]);
        const [searchproduct, setSearchproduct] = useState([]);
        const [invoice, setInvoice] = useState([]);
        const [result, setResult] = useState('');

        useEffect(() => {
            console.log('Trying to get products list');
            const data = {
                userid: user ? user.uid : null
            };
            axios
                .post(configData.LISTMYPRODUCTS_URL, data, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                })
                .then(res => {
                    console.log(res.data)
                    console.log('Received products list')
                    if (res.data instanceof Array) {
                        setMyproducts(res.data);
                    }
                })
        }, [])

        const createSale = (invoice) => {
            if (invoice.length === 0) {
                setResult('No invoice')
                return
            }

            console.log('Trying to handle sale');
            const data = {
                userid: user ? user.uid : null,
                invoice: invoice
            };
            axios
                .post(configData.SALE_URL, data, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                })
                .then(res => {
                    console.log('Received sales data')
                    console.log(res.data)
                    setResult(null);
                    if (res.data instanceof Object) {
                        for (var key of Object.keys(res.data)) {
                            if (res.data[key].code !== 1) {
                                setResult(result + '/' + res.data[key].data)
                            }
                        }
                    }
                    if (result.length == 0) {
                        setResult('Success')
                        setOption(<ListSales />)
                    }
                })
        }
        const searchHandler = (e) => {
            e.preventDefault();

            console.log('Trying to get search result');
            const data = {
                userid: user ? user.uid : null,
                searchstring: e.target.value
            };
            axios
                .post(configData.SEARCHMYPRODUCTS_URL, data, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                })
                .then(res => {
                    console.log(res.data)
                    if (res.data instanceof Array) {
                        setMyproducts(res.data)
                    }
                })
        }
        const addInvoice = (product, quantity, price, currency, currencyname) => {
            console.log(product)
            if (quantity < 1) {
                setResult('Quantity cannot be negative value')
                return
            }
            if (price < 0) {
                setResult('Price cannot be negative value')
                return
            }
            product.sellprice = price;
            product.sellquantity = quantity;
            product.sellcurrency = currency;
            product.sellcurrencyname = currencyname;
            setInvoice([...invoice, product])
        };
        const removeInvoice = (index) => {
            setInvoice(invoice.filter((product, index2) => index2 !== index))
        };
        const ItemRow = ({ product }) => {
            const [quantity, setQuantity] = useState(1);
            const [price, setPrice] = useState(product.price);

            return (
                <tr>
                    <td>{new Date(product.ts * 1000).toLocaleDateString("en-US")}</td>
                    <td>{product.id}</td>
                    <td>{product.category}</td>
                    <td>{product.quantity}</td>
                    <td>{product.name}</td>
                    <td><input onChange={e => setQuantity(e.target.value)} value={quantity} type="number" name="quantity" min="0" max="999" /></td>
                    <td><input onChange={e => setPrice(e.target.value)} value={price} type="number" name="price" min="0" max="999" /></td>
                    <td>{product.pricecurrencyname}</td>
                    <td><button onClick={() => addInvoice(product, quantity, price, product.pricecurrency, product.pricecurrencyname)}>Add</button></td>
                </tr>
            )
        }

        return (
            <div className='sales'>
                <h1 className='dashboard__title'>Sales</h1>
                <div className='dashboard__body'>
                    <input
                        type="text"
                        value={searchproduct}
                        placeholder='Search by ID or Name'
                        onKeyPress={
                            event => {
                                if (event.key === 'Enter') {
                                    searchHandler(event)
                                }
                            }
                        }
                        onChange={e => setSearchproduct(e.target.value)}
                    />
                    <table className='dashboard__table'>
                        <thead><tr><th>Ts</th><th>Id</th><th>Category</th><th>Quantity</th><th>Name</th><th>Quantity</th><th>Price</th><th>Currency</th><th></th></tr></thead>
                        {
                            myproducts.length &&
                            (
                                <tbody>
                                    {
                                        myproducts.map((product, index) => {
                                            return <ItemRow product={product} key={'tr' + index} />
                                        })
                                    }
                                </tbody>
                            )
                        }
                    </table>
                    <table className='dashboard__table'>
                        <thead><tr><th>Id</th><th>Name</th><th>Quantity</th><th>Price</th><th>Currency</th><th></th></tr></thead>
                        {
                            invoice.length &&
                            (
                                <tbody>
                                    {
                                        invoice.map((product, index) => {
                                            return (
                                                <tr key={'tr' + index}>
                                                    <td>{product.id}</td>
                                                    <td>{product.name}</td>
                                                    <td>{product.sellquantity}</td>
                                                    <td>{product.sellprice}</td>
                                                    <td>{product.sellcurrencyname}</td>
                                                    <td><button onClick={() => removeInvoice(index)}>Remove</button></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            )
                        }
                    </table>
                    {(result != null) && (<div className='dashboard__result'>{result}</div>)}
                    <button onClick={() => createSale(invoice)}>Create</button>
                </div>
            </div>
        )
    }
    function ListSales() {
        const [sales, setSales] = useState([]);
        const [searchsales, setSearchsales] = useState([]);
        const [result, setResult] = useState('');

        useEffect(() => {
            console.log('Trying to get sales list');
            const data = {
                userid: user ? user.uid : null
            };
            axios
                .post(configData.LISTSALES_URL, data, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                })
                .then(res => {
                    console.log(res.data)
                    console.log('Received sales list')
                    if (res.data instanceof Array) {
                        setSales(res.data);
                    }
                })
        }, [])

        const searchHandler = (e) => {
            e.preventDefault();

            console.log('Trying to get search result');
            const data = {
                userid: user ? user.uid : null,
                searchstring: e.target.value
            };
            axios
                .post(configData.SEARCHSALES_URL, data, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                })
                .then(res => {
                    console.log('Received sales search data')
                    if (res.data instanceof Array) {
                        setSales(res.data)
                    }
                })
        }
        const removeSale = (saleid) => {
            console.log('Trying to remove sale');
            const data = {
                userid: user ? user.uid : null,
                saleid: saleid
            };
            axios
                .post(configData.REMOVESALE_URL, data, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                })
                .then(res => {
                    console.log('Received remove sale data')
                    if (res.data instanceof Object) {
                        for (var key of Object.keys(res.data)) {
                            if (res.data[key].code !== 1) {
                                setResult(result + '/' + res.data[key].data)
                            }
                        }
                    }
                    if (result.length == 0) {
                        setResult('Success')
                        setSales(sales.filter((sale, itemIndex) => saleid != sale.salesid))
                    }
                })
        }

        return (
            <div className='listsales'>
                <h1 className='dashboard__title'>List Sales</h1>
                <div className='dashboard__body'>
                    <input
                        type="text"
                        value={searchsales}
                        placeholder='Search by ID or Name'
                        onKeyPress={
                            event => {
                                if (event.key === 'Enter') {
                                    searchHandler(event)
                                }
                            }
                        }
                        onChange={e => setSearchsales(e.target.value)}
                    />
                    <table className='dashboard__table'>
                        <thead><tr><th>Ts</th><th>Id</th><th>No Items</th><th></th></tr></thead>
                        {
                            sales && sales instanceof Array &&
                            (
                                <tbody>
                                    {
                                        sales.map((sale, index) => {
                                            return (
                                                <tr>
                                                    <td>{new Date(sale.ts * 1000).toLocaleDateString("en-US")}</td>
                                                    <td>{sale.salesid}</td>
                                                    <td>{sale.countitems} Items</td>
                                                    <td><button onClick={() => removeSale(sale.salesid)}>Remove</button></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            )
                        }
                    </table>
                    {(result != null) && (<div className='dashboard__result'>{result}</div>)}
                </div>
            </div>
        )
    }
    function EditProduct({ productid }) {
        const [name, setName] = useState('');
        const [description, setDescription] = useState('');
        const [condition, setCondition] = useState(0);
        const [conditionremarks, setConditionremarks] = useState('');
        const [cost, setCost] = useState('');
        const [currencyCost, setCurrencyCost] = useState('');
        const [price, setPrice] = useState('');
        const [currencyPrice, setCurrencyPrice] = useState('');
        const [quantity, setQuantity] = useState('');
        const [allocation, setAllocation] = useState('');
        const [category, setCategory] = useState('');
        const [tkey, setTkey] = useState([]);
        const [tdata, setTdata] = useState([]);
        const [technical, setTechnical] = useState([]);
        const [keyword, setKeyword] = useState('');
        const [keywords, setKeywords] = useState([]);
        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        const [previewimages, setPreviewimages] = useState([]);
        const [imagesToAdd, setImagesToAdd] = useState([]);
        const [imagesToDelete, setImagesToDelete] = useState([]);
        const [coverimage, setCoverimage] = useState(0);

        const [{ menu, currency }] = useStateValue();

        useEffect(() => {
            console.log('Trying to get product details');
            const url = configData.PRODUCT_URL
            const data = {
                productid: productid
            };
            axios
                .post(url, data, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                })
                .then(res => {
                    console.log('Received product data');
                    const product = res.data;
                    if (product instanceof Object) {
                        setPreviewimages(product.images)
                        setName(product.name)
                        setDescription(product.description)
                        setCondition(product.condition)
                        setConditionremarks(product.conditionremarks)
                        setCategory(parseInt(product.category))
                        setCost(parseInt(product.cost))
                        setCurrencyCost(product.currencycost)
                        setPrice(parseInt(product.price))
                        setCurrencyPrice(product.currencyprice)
                        setQuantity(parseInt(product.quantity))
                        setAllocation(product.allocation)
                        setTechnical(product.technical)
                        setKeywords(product.keywords)
                    }
                    else {
                        console.log('No product data')
                    }
                })
        }, [])


        const handleDescription = (e) => {
            setDescription(e.target.value)
            e.target.style.height = 'inherit';
            e.target.style.height = '${e.target.scrollHeight}px';
        }
        const handleSubmit = e => {
            e.preventDefault();

            console.log('Trying to edit product');
            setLoading(true);

            const editProductFormData = new FormData();
            editProductFormData.append("userid", user ? user.uid : '');
            for (let i = 0; i < imagesToAdd.length; i++) {
                editProductFormData.append("file" + i, imagesToAdd[i].file);
            }
            editProductFormData.append("coverimage", coverimage);
            editProductFormData.append("productid", productid);
            editProductFormData.append("name", name);
            editProductFormData.append("description", description);
            editProductFormData.append("condition", condition);
            editProductFormData.append("conditionremarks", conditionremarks);
            editProductFormData.append("category", category);
            editProductFormData.append("cost", cost);
            editProductFormData.append("currencycost", currencyCost);
            editProductFormData.append("price", price);
            editProductFormData.append("currencyprice", currencyPrice);
            editProductFormData.append("quantity", quantity);
            editProductFormData.append("allocation", allocation);
            editProductFormData.append("imagestodelete", JSON.stringify(imagesToDelete));
            editProductFormData.append("technical", JSON.stringify(technical));
            editProductFormData.append("keywords", JSON.stringify(keywords));

            axios
                .post(configData.EDITPRODUCT_URL, editProductFormData, {
                    headers: {
                        Accept: "application/json",
                        'Content-Type': 'multipart/form-data'
                    },
                })
                .then(res => {
                    setResult(JSON.stringify(res.data));
                    setLoading(false)
                })
        }
        const handleDelete = e => {
            e.preventDefault();
            setLoading(true);

            console.log('Trying to delete product');
            const data = {
                productid: productid
            };
            axios
                .post(configData.DELETEPRODUCT_URL, data, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                })
                .then(res => {
                    setResult(JSON.stringify(res.data));
                    setLoading(false)
                })
        }
        const removeKeyword = name => {
            console.log('Deleting keyword')
            setKeywords(keywords.filter(keyword => keyword !== name))
        }
        const removeTechnical = key => {
            console.log('Deleting technical')
            setTechnical(technical.filter(
                (item, itemIndex) => key !== itemIndex
            ))
        }
        const addKeyword = () => {
            if (keyword) setKeywords([...keywords, keyword]);
            setKeyword('')
        }
        const addTechnical = () => {
            if (tkey && tdata) setTechnical(technical => [...technical, [tkey, tdata]])
            setTdata('');
            setTkey('');
        }
        const removeImage = key => {
            setPreviewimages(previewimages.filter((item, itemIndex) => key !== itemIndex))
            setImagesToDelete([...imagesToDelete, key]);
            console.log('Remove image')
        }
        const makeCover = (e, imageno) => {
            setCoverimage(imageno);
            var element = document.getElementsByClassName('editproduct__previewimagecover');
            for (var i = 0; i < element.length; i++) {
                element[i].style.color = 'white';
            }

            e.target.style.color = 'black'
        }

        return (
            <div className='editproduct'>
                <h1 className='dashboard__title'>Edit Product</h1>
                <div className='dashboard__status'>productid #{productid}</div>
                <div className='dashboard__body'>
                    <div className='editproduct__previewimages'>
                        {
                            previewimages ? previewimages.map((url, index) => {
                                return (
                                    <div className="editproduct__previewimageswrap">
                                        <span className="editproduct__previewimagecover" onClick={(e) => makeCover(e, index)}>C</span>
                                        <span className="editproduct__previewimagedelete" onClick={() => removeImage(index)}>x</span>
                                        <img key={'editproduct__previewimage' + index} className={'editproduct__image'} src={url} />
                                    </div>)
                            }) : null
                        }
                        <RUG
                            onChange={(images) => {
                                setImagesToAdd(images)
                            }}
                        />
                    </div>
                    <label>Product Name</label>
                    <input placeholder="Name" defaultValue={name} onChange={e => setName(e.target.value)} />
                    <label>Product Description</label>
                    <textarea rows='7' placeholder="Description" defaultValue={description} onChange={handleDescription} />
                    <label>Product Condition</label>
                    <select placeholder="Condition" value={condition} onChange={e => setCondition(e.target.value)}>
                        <option value='0'>Mint</option>
                        <option value='1'>Good</option>
                        <option value='2'>Fair</option>
                        <option value='3'>Poor</option>
                    </select>
                    <label>Condition Remarks</label>
                    <textarea rows='3' placeholder="Condition remarks" defaultValue={conditionremarks} onChange={e => setConditionremarks(e.target.value)} />
                    <label>Product Category</label>
                    <select placeholder="Category" value={category} onChange={e => setCategory(e.target.value)} >
                        {
                            menu && menu.firstcategory ? menu.firstcategory.map((category1, index) => {
                                return (
                                    <>
                                        <option disabled key={'category1option' + index} className='product__categoryoption1' value={category1['@attributes'].id}>
                                            {category1['@attributes'].id + ' ' + category1['@attributes'].name}
                                        </option>
                                        {
                                            category1.secondcategory && category1.secondcategory instanceof Array ? category1.secondcategory.map((category2, index2) => {
                                                return (
                                                    <>
                                                        <option key={'category2option' + index2} className='product__categoryoption2' value={category2['@attributes'].id}>
                                                            {'\xA0\xA0' + category2['@attributes'].id + ' ' + category2['@attributes'].name}
                                                        </option>
                                                        {
                                                            category2.thirdcategory && category2.thirdcategory instanceof Array ? category2.thirdcategory.map((category3, index3) => {
                                                                return (
                                                                    <option key={'category3option' + index3} className='product__categoryoption3' value={category3['@attributes'].id}>
                                                                        {'\xA0\xA0\xA0\xA0' + category3['@attributes'].id + ' ' + category3['@attributes'].name}
                                                                    </option>
                                                                )
                                                            }) : category2.thirdcategory instanceof Object &&
                                                            <option key={'category3option1'} className='product__categoryoption3' value={category2.thirdcategory['@attributes'].id}>
                                                                {'\xA0\xA0' + category2.thirdcategory['@attributes'].id + ' ' + category2.thirdcategory['@attributes'].name}
                                                            </option>
                                                        }
                                                    </>
                                                )
                                            }) : category1.secondcategory instanceof Object &&
                                            <option key={'category2option1'} className='product__categoryoption2' value={category1.secondcategory['@attributes'].id}>
                                                {'\xA0\xA0' + category1.secondcategory['@attributes'].id + ' ' + category1.secondcategory['@attributes'].name}
                                            </option>
                                        }
                                    </>
                                );
                            }) : <option>List not received</option>
                        }
                    </select>
                    <label>Product Cost</label>
                    <div className='dashboard__inputgroup'>
                        <input placeholder="Cost" defaultValue={cost} onChange={e => setCost(e.target.value)} />
                        <select defaultValue='0' value={currencyCost} onChange={e => setCurrencyCost(e.target.value)} >
                            {
                                currency instanceof Array ? currency.map((curr, index) => {
                                    return (
                                        <option value={index}>{curr.currency}</option>
                                    );
                                }) : <option>List not received</option>
                            }
                        </select>
                    </div>
                    <label>Product Price</label>
                    <div className='dashboard__inputgroup'>
                        <input placeholder="Price" defaultValue={price} onChange={e => setPrice(e.target.value)} />
                        <select defaultValue='0' value={currencyPrice} onChange={e => setCurrencyPrice(e.target.value)} >
                            {
                                currency instanceof Array ? currency.map((curr, index) => {
                                    return (
                                        <option value={index}>{curr.currency}</option>
                                    );
                                }) : <option>List not received</option>
                            }
                        </select>
                    </div>
                    <label>Product Quantity</label>
                    <input placeholder="Quantity" defaultValue={quantity} onChange={e => setQuantity(e.target.value)} />
                    <label>Product Allocation</label>
                    <input placeholder="Allocation ex: (Country-Warehouse-Shelf no.)" defaultValue={allocation} onChange={e => setAllocation(e.target.value)} />
                    <label>Product Technical Details</label>
                    <input placeholder="Key" value={tkey} onChange={e => setTkey(e.target.value)} />
                    <input placeholder="Data" value={tdata} onChange={e => setTdata(e.target.value)} />
                    <button onClick={addTechnical}>Add Technical Detail</button>
                    <div className='editproduct__previewtechnical'>
                        {
                            technical instanceof Array && technical.length ?
                                (
                                    <table className='dashboard__table'>
                                        <tbody>
                                            {
                                                technical.map((technical, index) => {
                                                    return (
                                                        <tr key={'tr' + index}>
                                                            <td key={'tdleft' + index}>{technical[0]}</td>
                                                            <td key={'tdright' + index}>{technical[1]}</td>
                                                            <td key={'tddelete' + index}><span onClick={() => removeTechnical(index)}>x</span></td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                ) : null
                        }
                    </div>
                    <label>Product Keywords</label>
                    <input placeholder="Keywords" value={keyword} onChange={e => setKeyword(e.target.value)} />
                    <button onClick={addKeyword}>Add Keyword</button>
                    <div className='editproduct__previewkeywords'>
                        {
                            keywords instanceof Array && keywords.length ? keywords.map((keyword) => {
                                return <>
                                    <div className='editproduct__keyword'>{keyword}</div>
                                    <span onClick={() => removeKeyword(keyword)}>x</span>
                                </>;
                            }) : null
                        }
                    </div>
                    <div className='editproduct__result'>
                        {result ? JSON.stringify(result) : null}
                    </div>
                    <div className='editproduct__spinner'>
                        <RotateSpinner size={30} color="#686769" loading={loading} />
                    </div>
                    <button onClick={handleDelete}>Delete</button>
                    <button onClick={handleSubmit}>Save</button>
                </div>
            </div>
        )
    }

    function Admin() {
        const [adminOption, setAdminOption] = useState(<PendingApprovals />);
        const [searchproduct, setSearchproduct] = useState();

        function Approvals() {
            const [approvals, setApprovals] = useState();

            useEffect(() => {
                console.log('Trying to get approvals');
                axios
                    .post(configData.APPROVALS_URL, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                    })
                    .then(res => {
                        console.log('Received approvals')
                        if (res.data instanceof Array) {
                            setApprovals(res.data);
                        }
                        else {
                            setApprovals([]);
                        }
                    })
            }, [])
            const approveProduct = (productid) => {
                console.log('Trying to approve product');
                const data = {
                    productid: productid
                };
                axios
                    .post(configData.APPROVEPRODUCT_URL, data, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                    })
                    .then(res => {
                        setApprovals(approvals.filter((item, itemIndex) => productid !== item.id))
                    })
            }
            const disapproveProduct = (productid) => {
                console.log('Trying to disapprove product');
                const data = {
                    productid: productid
                };
                axios
                    .post(configData.DISAPPROVEPRODUCT_URL, data, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                    })
                    .then(res => {
                        setApprovals(approvals.filter((item, itemIndex) => productid !== item.id))
                    })
            }
            const deleteProduct = (productid) => {
                console.log('Trying to delete product');
                const data = {
                    productid: productid
                };
                axios
                    .post(configData.DELETEPRODUCT_URL, data, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                    })
                    .then(res => {
                        setApprovals(approvals.filter((item, itemIndex) => productid !== item.id))
                    })
            }
            const searchHandler = (e) => {
                e.preventDefault();

                console.log('Trying to get search result');
                const data = {
                    searchstring: e.target.value
                };
                axios
                    .post(configData.SEARCHPRODUCTLIST_URL, data, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                    })
                    .then(res => {
                        if (res.data instanceof Array) {
                            setApprovals(res.data);
                        }
                    })
            }
            return (
                <div className="dashboard__body">
                    <input
                        type="text"
                        value={searchproduct}
                        placeholder='Search by ID or Name'
                        onKeyPress={
                            event => {
                                if (event.key === 'Enter') {
                                    searchHandler(event)
                                }
                            }
                        }
                        onChange={e => setSearchproduct(e.target.value)}
                    />
                    <table className='dashboard__table'>
                        <thead><tr><th>Id</th><th>Ts</th><th>Name</th><th>Status</th><th>Option</th></tr></thead>
                        <tbody>
                            {
                                approvals ? approvals.map((product, index) => {
                                    return (
                                        <tr key={'tr' + index}>
                                            <td>{product.id}</td>
                                            <td>{new Date(product.ts * 1000).toLocaleDateString("en-US")}</td>
                                            <td><Link to={'/product/' + product.id} >{product.name}</Link></td>
                                            <td>{product.status}</td>
                                            <td>
                                                <select>
                                                    <option>Pending Approval</option>
                                                    <option>Approve</option>
                                                    <option>Disapprove</option>
                                                </select>
                                            </td>
                                        </tr>
                                    );
                                })
                                    : null
                            }
                        </tbody>

                    </table>
                </div>
            )
        }
        function PendingApprovals() {
            const [approvals, setApprovals] = useState();

            useEffect(() => {
                console.log('Trying to get entries need approval');
                axios
                    .post(configData.PENDINGAPPROVALS_URL, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                    })
                    .then(res => {
                        console.log('Received entries');
                        if (res.data instanceof Array) {
                            setApprovals(res.data);
                        }
                        else {
                            setApprovals([]);
                        }
                    })
            }, [])
            const approveProduct = (productid) => {
                console.log('Trying to approve product');
                const data = {
                    productid: productid
                };
                axios
                    .post(configData.APPROVEPRODUCT_URL, data, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                    })
                    .then(res => {
                        setApprovals(approvals.filter((item, itemIndex) => productid !== item.id))
                    })
            }
            const disapproveProduct = (productid) => {
                console.log('Trying to disapprove product');
                const data = {
                    productid: productid
                };
                axios
                    .post(configData.DISAPPROVEPRODUCT_URL, data, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                    })
                    .then(res => {
                        setApprovals(approvals.filter((item, itemIndex) => productid !== item.id))
                    })
            }
            const deleteProduct = (productid) => {
                console.log('Trying to delete product');
                const data = {
                    productid: productid
                };
                axios
                    .post(configData.DELETEPRODUCT_URL, data, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                    })
                    .then(res => {
                        setApprovals(approvals.filter((item, itemIndex) => productid !== item.id))
                    })
            }
            return (
                <table className='dashboard__table'>
                    <thead><tr><th>Id</th><th>Ts</th><th>Cat</th><th>Name</th><th>Status</th><th>Action</th></tr></thead>
                    <tbody>
                        {
                            approvals ? approvals.map((product, index) => {
                                return (
                                    <tr key={'tr' + index}>
                                        <td>{product.id}</td>
                                        <td>{new Date(product.ts * 1000).toLocaleDateString("en-US")}</td>
                                        <td>{product.category}</td>
                                        <td><Link to={'/product/' + product.id} >{product.name}</Link></td>
                                        <td>{product.status}</td>
                                        <td><div><button onClick={() => approveProduct(product.id)}>Approve</button><button onClick={() => disapproveProduct(product.id)}>Disapprove</button></div></td>
                                    </tr>
                                );
                            })
                                : null
                        }
                    </tbody>

                </table>
            )
        }
        function UserControl() {
            const [users, setUsers] = useState();
            const [authordetails, setAuthordetails] = useState({});
            const [searchusers, setSearchUsers] = useState();
            const [modal, setModal] = useState(false);

            let subtitle;
            function afterOpenModal() {
                subtitle.style.color = '#f00';
            }

            useEffect(() => {
                console.log('Trying to get users list');
                axios
                    .post(configData.GETUSERSLIST_URL, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                    })
                    .then(res => {
                        console.log('Received users list');
                        if (res.data instanceof Array) {
                            setUsers(res.data);
                        }
                        else {
                            setUsers([]);
                        }
                    })
            }, [])

            const searchHandler = (e) => {
                e.preventDefault();

                console.log('Trying to get search result');
                const data = {
                    userid: e.target.value
                };
                axios.post(configData.SEARCHUSERS_URL, data, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                }).then(res => {
                    console.log('Received search result')
                    console.log(res.data)
                    if (res.data instanceof Array) {
                        setUsers(res.data);
                    }
                })
            }
            const getUserProfile = (userid) => {
                console.log('Trying to get user profile');
                const data = {
                    userid: userid
                };
                axios.post(configData.GETUSERPROFILE_URL, data, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                }).then(res => {
                    console.log('User profile received')
                    console.log(res.data)
                    if (res.data instanceof Object) {
                        setAuthordetails(res.data)
                        setModal(true)
                    }
                })
            }

            return (
                <div className="dashboard__body">
                    <Modal
                        isOpen={modal}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={() => setModal(false)}
                        style={customStyles}
                        contentLabel="Profile Modal"
                    >
                        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>{authordetails.username}</h2>
                        <img src={authordetails.profilepic} />
                        <p>{authordetails.biography}</p>
                        <button onClick={() => setModal(false)}>Close</button>
                    </Modal>
                    <input
                        type="text"
                        value={searchproduct}
                        placeholder='Search by ID'
                        onKeyPress={
                            event => {
                                if (event.key === 'Enter') {
                                    searchHandler(event)
                                }
                            }
                        }
                        onChange={e => setSearchUsers(e.target.value)}
                    />
                    <table className='dashboard__table'>
                        <thead><tr><th>Key</th><th>User ID</th><th>Fullname</th><th>Username</th><th>User Type</th><th>SubBeg</th><th>SubExp</th></tr></thead>
                        <tbody>
                            {
                                users ?
                                    (
                                        users.map((user, index) => {
                                            return (
                                                <tr key={'tr' + index}>
                                                    <td>{user.key}</td>
                                                    <td><div className='dashboard__tablelink' onClick={() => getUserProfile(user.userid)}>{user.userid}</div></td>
                                                    <td>{user.name}</td>
                                                    <td>{user.username}</td>
                                                    <td>{user.usertype}</td>
                                                    <td>{user.subbeg}</td>
                                                    <td>{user.subexp}</td>
                                                </tr>
                                            );
                                        })
                                    )
                                    : null
                            }
                        </tbody>
                    </table>
                </div>
            )
        }

        return (
            <div className='admin'>
                <h1 className='dashboard__title'>Admin</h1>
                <div className='dashboard__status'>As {usertypename}</div>
                <div className='admin__navmenu'>
                    <div className='admin__menuitem' onClick={() => setAdminOption(<Approvals />)}>Approvals</div>
                    <div className='admin__menuitem' onClick={() => setAdminOption(<PendingApprovals />)}>Pending Approvals</div>
                    <div className='admin__menuitem' onClick={() => setAdminOption(<UserControl />)}>User Control</div>
                </div>
                <div className='admin__panel'>{adminOption}</div>
            </div>
        )
    }
    function ManageProducts() {
        const [manageproductsoption, setManageproductsoption] = useState(<ProductsList />);
        const [searchproduct, setSearchProduct] = useState();
        const [{ usertypename }] = useStateValue();


        function ProductsList() {
            const [productslist, setProductsList] = useState();

            useEffect(() => {
                console.log('Trying to get products list');
                axios
                    .post(configData.PRODUCTSLIST_URL, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                    })
                    .then(res => {
                        console.log('Received products list')
                        if (res.data instanceof Array) {
                            setProductsList(res.data);
                        }
                    })
            }, [])

            const searchHandler = (e) => {
                e.preventDefault();

                console.log('Trying to get search result');
                const data = {
                    searchstring: e.target.value
                };
                axios
                    .post(configData.SEARCHPRODUCTLIST_URL, data, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                    })
                    .then(res => {
                        console.log('Received search result')
                        if (res.data instanceof Array) {
                            setProductsList(res.data);
                        }
                    })
            }
            const handleEdit = (e) => {
                setOption(<EditProduct productid={e} />)
            }

            return (
                <div className="dashboard__body">
                    <input
                        type="text"
                        value={searchproduct}
                        placeholder='Search by ID or Name'
                        onKeyPress={
                            event => {
                                if (event.key === 'Enter') {
                                    searchHandler(event)
                                }
                            }
                        }
                        onChange={e => setSearchProduct(e.target.value)}
                    />
                    <table className='dashboard__table'>
                        <thead><tr><th>Id</th><th>Ts</th><th>Cat</th><th>Name</th></tr></thead>
                        <tbody>
                            {
                                productslist ? productslist.map((product, index) => {
                                    return (
                                        <tr key={'tr' + index}>
                                            <td>{product.id}</td>
                                            <td>{new Date(product.ts * 1000).toLocaleDateString("en-US")}</td>
                                            <td>{product.categoryname}</td>
                                            <td><div className='dashboard__tablelink' onClick={() => handleEdit(product.id)}>{product.name}</div></td>
                                        </tr>
                                    );
                                })
                                    : null
                            }
                        </tbody>
                    </table>
                    <Link to="/allproducts">
                        <button className='myproducts__excelbutton'>
                            All Products Excel
                        </button>
                    </Link>
                </div>
            )
        }
        return (
            <div className='manageproducts'>
                <h1 className='dashboard__title'>Manage Products</h1>
                <div className='dashboard__status'>As {usertypename}</div>
                <div className='admin__navmenu'>
                    <div className='admin__menuitem' onClick={() => setManageproductsoption(<ProductsList />)}>Products List</div>
                </div>
                <div className='admin__panel'>{manageproductsoption}</div>
            </div>
        )
    }
    function MenuEditor() {
        const [result, setResult] = useState();

        const handleSubmit = (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            console.log('Trying to get submit new menu');
            axios
                .post(configData.SAVEMENU_URL, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                })
                .then(res => {
                    console.log('Search log data received')
                    console.log(res.data)
                    if (res.data instanceof Object) {
                        setResult(res.data.data)
                    }
                })
        }
        function MenuItem({ menuid, menuname }) {
            const [id, setId] = useState(menuid);
            const [name, setName] = useState(menuname);

            return (
                <tr >
                    <td><input type="number" value={id} name={'id' + menuid} onChange={(e) => setId(e.target.value)} /></td>
                    <td><input value={name} name={'name' + menuid} onChange={(e) => setName(e.target.value)} /></td>
                </tr>
            )
        }

        return (
            <div className='menueditor'>
                <h1 className='dashboard__title'>Menu Editor</h1>
                <div className='dashboard__body'>
                    <form onSubmit={handleSubmit}>
                        <table className='dashboard__menutable'>
                            <thead><tr><th>Id</th><th>Name</th></tr></thead>
                            <tbody>
                                {
                                    menu && menu.firstcategory ? menu.firstcategory.map((category1, index) => {
                                        return (
                                            <>
                                                <MenuItem key={'menuitem' + index} menuid={category1['@attributes'].id} menuname={category1['@attributes'].name} />
                                                {
                                                    category1.secondcategory && category1.secondcategory instanceof Array ? category1.secondcategory.map((category2, index2) => {
                                                        return (
                                                            <>
                                                                <MenuItem key={'menuitem2' + index2} menuid={category2['@attributes'].id} menuname={category2['@attributes'].name} />
                                                                {
                                                                    category2.thirdcategory && category2.thirdcategory instanceof Array ? category2.thirdcategory.map((category3, index3) => {
                                                                        return (
                                                                            <MenuItem key={'menuitem3' + index3} menuid={category3['@attributes'].id} menuname={category3['@attributes'].name} />
                                                                        )
                                                                    }) : category2.thirdcategory instanceof Object &&
                                                                    <MenuItem key={'menuitem2' + index2} menuid={category2.thirdcategory['@attributes'].id} menuname={category2.thirdcategory['@attributes'].name} />
                                                                }
                                                                <MenuItem key={'menuitem'} menuid='' menuname='' />
                                                            </>
                                                        )
                                                    }) : category1.secondcategory instanceof Object &&
                                                    <MenuItem key={'menuitem2'} menuid={category1.secondcategory['@attributes'].id} menuname={category1.secondcategory['@attributes'].name} />
                                                }
                                                <MenuItem key={'menuitem'} menuid='' menuname='' />
                                            </>
                                        );
                                    }) : <div>List not received</div>
                                }
                            </tbody>
                        </table>
                        <div className='dashboard__result'>
                            {result && result}
                        </div>
                        <button type="submit">Save</button>
                    </form>
                </div>
            </div>
        )
    }
    function Subscriptions() {
        return (
            <div className='subscriptions'>Dashboards</div>
        )
    }

}

export default Dashboard