import React, { useState } from 'react'
import './Login.css'
import { useNavigate } from 'react-router-dom'
import { auth } from './Firebase'
import { sendPasswordResetEmail } from "firebase/auth";
import './Forgot.css'

function Forgot() {
    const history = useNavigate();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const forgot = e => {
        e.preventDefault();

        if(!email){
            setError('Please enter email')
            return
        }

        sendPasswordResetEmail(auth, email)
            .then(() => {
                console.log('Email reset sent');
                history('/');
            })
            .catch((error) => {
                console.log('User not autheticated');
                setError(error.message);
            });
    }

    return (
        <div className="forgot">
            <div className="forgot__container">
                <h1>Forgot Password</h1>
                <form>
                    <h5>E-mail</h5>
                    <input value={email} type='text' onChange={e => setEmail(e.target.value)} />

                    {(error != '') ? (<div className='forgot__error'>{error}</div>) : null}
                    <button className="forgot__forgotButton" type="submit" onClick={forgot}>Forgot Password</button>
                </form>
            </div>
        </div>
    );
}

export default Forgot