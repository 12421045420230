import React, { useState } from 'react'
import './NavMenu.css'
import { Link, useNavigate } from 'react-router-dom'

function NavMenu() {
    const history = useNavigate();

    const openMenu = () => {
        history('/menu');
    }
    const openForum = () => {
        history('/forum');
    }

    return (
        <div className='navmenu'>
            <button className='navmenu__button' onClick={openMenu}>MENU</button>
        </div>
    )
}

export default NavMenu