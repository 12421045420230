import React from 'react'
import './NoMatch.css'
import { Link } from 'react-router-dom'

function NoMatch() {
  return (
    <div className='nomatch'>
        <div className='nomatch__title'>404</div>
        <div className='nomatch__caption'>Ooops!!</div>
        <div className='nomatch__text'>That page doesn't exist or is unavailable.</div>
        <Link to='/'><button className='nomatch__homebutton'>Go Back Home</button></Link>
    </div>
  )
}

export default NoMatch