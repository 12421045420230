import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBu2SWw7JJddjIFqeJPGjxFHWvymm45mR4",
  authDomain: "yzmarket.firebaseapp.com",
  projectId: "yzmarket",
  storageBucket: "yzmarket.appspot.com",
  messagingSenderId: "56718927876",
  appId: "1:56718927876:web:04ed1ee80998548ee42d51",
  measurementId: "G-J9G641NB9G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth();
const db = getFirestore(app);

export {auth, db};