import React, { useState, useEffect } from "react";
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './Header';
import Home from './Home';
import Checkout from './Checkout';
import Login from './Login';
import Register from './Register';
import Forgot from './Forgot';
import ChangePassword from './ChangePassword';
import Search from './Search';
import Forum from './Forum';
import Terms from './Terms';
import AllProducts from './AllProducts';
import MyProducts from './MyProducts';
import ProductInfo from './ProductInfo';
import ProductCategory from './ProductCategory';
import CheckoutForm from './CheckoutForm';
import Dashboard from './Dashboard';
import Plans from './Plans';
import Menu from './Menu';
import NoMatch from './NoMatch';
import { useStateValue } from "./StateProvider";
import { auth } from "./Firebase";
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { Helmet } from "react-helmet";
import configData from './Config.json';
import axios from 'axios';

const promise = loadStripe("pk_test_51KXXGOLfW5mz0NZGMHQlOaEtbmBBE2Y9oxxyorJhhX9zXBvOzK7e3AtnROI3IeJO31wZhuR375CAaffgHPSZDmv8005QpqqGkY")

function App() {
  const [{ }, dispatch] = useStateValue();
  const [mobilemode, setMobileMode] = useState(0);

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      console.log('Trying to get user avatar');
      const data = {
        userid: authUser.uid
      };
      axios
        .post(configData.GETPROFILE_URL, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then(res => {
          console.log(res.data)
          if (res.data instanceof Object) {
            if (authUser) {
              dispatch({
                type: "SET_USER",
                user: { ...authUser, ...res.data },
              });
            } else {
              dispatch({
                type: "SET_USER",
                user: null,
              });
            }
          }
          else {
            if (authUser) {
              dispatch({
                type: "SET_USER",
                user: authUser
              });
            } else {
              dispatch({
                type: "SET_USER",
                user: null,
              });
            }
          }
        })
    });


    console.log('Trying to get menu')
    const url = configData.MENULIST_URL
    axios
      .post(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then(res => {
        dispatch({
          type: "SET_MENU",
          menu: res.data,
        });
      })

    window.innerWidth < 720 ? setMobileMode(1) : setMobileMode(0);

  }, []);

  return (
    <div className="app">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Yacht Zone Market</title>
        <link rel="canonical" href="https://www.yachtzonemarket.com" />
        <meta name="description" content="Yacht zone market, yacht parts and marine products based in Dubai, UAE " />
      </Helmet>
      <BrowserRouter>
        <Header mobilemode={mobilemode} />
        <Routes>
          <Route path="/" >
            <Route index path="/" element={<Home />} />
            <Route path="product/:id" element={<ProductInfo />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="login" element={<Login />} />
            <Route path="forgot" element={<Forgot />} />
            <Route path="register" element={<Register />} />
            <Route path="changepassword" element={<ChangePassword />} />
            <Route path="forum" element={<Forum />} />
            <Route path="payment" element={
              <Elements stripe={promise}>
                <CheckoutForm />
              </Elements>} />
            <Route path="search" element={<Search />} />
            <Route path="terms" element={<Terms />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="menu" element={<Menu />} />
            <Route path="productcategory/:id" element={<ProductCategory />} />
            <Route path="plans" element={<Plans />} />
            <Route path="myproducts" element={<MyProducts />} />
            <Route path="allproducts" element={<AllProducts />} />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
