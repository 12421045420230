import React, { useState } from 'react'
import './Login.css'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from './Firebase'
import { signInWithEmailAndPassword } from "firebase/auth";

function Login() {
    const history = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const signIn = e => {
        e.preventDefault();

        if(!email){
            setError('Please enter email')
            return
        }
        if(!password){
            setError('Please enter password')
            return
        }

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                console.log('User Autheticated');
                history('/');
            })
            .catch((error) => {
                console.log('User not autheticated');
                setError(error.code);
            });
    }

    return (
        <div className="login">
            <div className="login__container">
                <h1>Sign-in</h1>
                <form>
                    <h5>E-mail</h5>
                    <input value={email} type='text' onChange={e => setEmail(e.target.value)} />

                    <h5>Password</h5>
                    <input autoComplete="on" value={password} type='password' onChange={e => setPassword(e.target.value)} />

                    {(error != '') ? (<div className='login__error'>{error}</div>) : null}
                    <button className="login__singinButton" type="submit" onClick={signIn}>Sign In</button>
                    <Link to="/forgot">
                        <button className="login__forgotButton" type="submit">Forgot password ?</button>
                    </Link>
                    <Link to="/register">
                        <button className="login__registerButton" type="submit">Create your account</button>
                    </Link>
                </form>
            </div>
        </div>
    );
}

export default Login