import React from 'react'
import './Forum.css'

function Forum() {
  return (
    <div className='forum'>

    </div>
  )
}

export default Forum